import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import './library.css.less'
import { Grid, Container, Image, Divider, Button } from 'semantic-ui-react'
import libImg1 from '../images/library1.png'
import libImg2 from '../images/library2.png'
import bgLib2 from '../images/city-blue-mini.png'
import bgLib1 from '../images/cptable-blue.png'
import mslantGlass from '../images/mag-slant-white.png'

const Library = () => {
	return (
		<Layout>
			<Seo title="Library" />
			<div className="library-container">
				<div className="color-overlay"></div>
				<Container>
					<img src={bgLib1} alt="" className="bg-img1" />
					<Grid stackable centered columns={2}>
						<Grid.Column width={9} className="library-content">
							<Grid columns="equal">
								<Grid.Column className="library-column">
									<h4 className="header-title">
										The{' '}
										<i>
											future of <br />
											legal knowledge
										</i>
										<br />
										access is through
										<br /> the cloud.
									</h4>
									<br />
									<Divider fitted className="library" />
									<br />

									<span>
										With MyLegalWhiz, libraries <br />
										can now opt to allow easy
										<br /> and unimpeded access to <br /> organized and dynamic{' '}
										<br /> content for their students.
									</span>
								</Grid.Column>
								<Grid.Column width={8}>
									<Image src={libImg1} />
								</Grid.Column>
							</Grid>
						</Grid.Column>
						<Grid.Row />
						<img src={bgLib2} className="bg-img2" alt="" />
						<Grid.Column width={9} className="library-content">
							<Grid columns="equal">
								<Grid.Column width={8}>
									<Image src={libImg2} />
								</Grid.Column>
								<Grid.Column>
									<h4 className="header-title">
										For library and institutional subscriptions
									</h4>
									<br />
									<Divider fitted className="library" />
									<br />

									<span>
										Simultaneous access <br />
										IP restrictions
										<br /> Unlimited users
									</span>
									<br />
									<br />

									<Button inverted href="contact" className="btn-contact">
										GET IN TOUCH
									</Button>
								</Grid.Column>
							</Grid>
						</Grid.Column>
					</Grid>
				</Container>
				<img src={mslantGlass} alt="" className="library-mslantGlass" />
			</div>
		</Layout>
	)
}

export default Library
